<template>
  <div class="message-container" :class="{'night': isDay}" v-if="messageDisplayTime > 0">
    <MessageViewer
      :id="0"
      :delay="200"
      :enabled="applicationEnabled"
      :imagesEnabled="imagesEnabled"
      :msg-duration="messageDisplayTime"
      :is-day="isDay"
      :temp-messages="spMessages"
      :special-message-enabled="specialMessages"
      :panic-mode-delay="panicModeDelay"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import dayjs from 'dayjs';
import MessageViewer from '@/components/MessageViewer.vue';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export default {
  name: 'Home',
  components: {
    MessageViewer,
  },
  data() {
    return {
      applicationEnabled: false,
      imagesEnabled: false,
      panicModeDelay: 0,
      specialMessages: false,
      spMessages: '',
      messageDisplayTime: 0,
      numChannels: 0,
      dayTime: '',
      nighTime: '',
      isDay: null,
      doDay: '',
      doNight: '',
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/settings`)
      .then((response) => {
        console.log(response);
        this.applicationEnabled = (response.data.data[0].metavalue !== '0');
        this.imagesEnabled = (response.data.data[35].metavalue !== '0');
        this.numChannels = parseInt(response.data.data[3].metavalue, 10);
        this.messageDisplayTime = parseInt(response.data.data[1].metavalue, 10);
        this.specialMessages = (response.data.data[9].metavalue !== '0');
        this.spMessages = response.data.data[10].metavalue.split(',');
        this.dayTime = response.data.data[4].metavalue.replace('.', ':');
        this.nighTime = response.data.data[5].metavalue.replace('.', ':');
        this.panicModeDelay = parseInt(response.data.data[36].metavalue, 10);
        const tempDate = new Date();
        const dayjsObjDayTime = dayjs(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), this.dayTime.split(':')[0], this.dayTime.split(':')[1]));
        const dayjsObjNightTime = dayjs(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), this.nighTime.split(':')[0], this.nighTime.split(':')[1]));
        this.doDay = dayjsObjDayTime;
        this.doNight = dayjsObjNightTime;

        this.isDay = dayjs()
          .isBetween(dayjsObjDayTime, dayjsObjNightTime);
      });

    setInterval(() => {
      this.isDay = dayjs()
        .isBetween(this.doDay, this.doNight);
      console.log('check', this.isDay);
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background: transparent;
}

.vue-grid-layout {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
}
</style>
