import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(new VueSocketIO({
  debug: true,
  connection: `${process.env.VUE_APP_API_URL}`,
  options: {
    query: {
      clientID: 'vooble',
    },
  },
}));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
